import { graphql, PageProps } from "gatsby";
import React from "react";
import { Footer } from "../components/Footer";
import { Hero } from "../components/Hero";
import { Nav } from "../components/Nav";
import { PostCard } from "../components/PostCard";
import { SEO } from "../components/SEO";


const FeaturePage = (props: PageProps<{ data: Queries.WpPost }>) => {
	return (
		<>
			<Nav navGroup={props.data.data.page_meta!.navLink!} />
			<Hero
				hero={{
					title: props.data.data.title,
					image: props.data.data.featuredImage!.node!,
					byline: props.data.data.excerpt,
					callToAction: {} as any,
				} as any}
				enablePadding={false}
				haveLink={false}
			/>
			<section className="bg-light border-top py-3">
				<div className="container">
					<div dangerouslySetInnerHTML={{ __html: props.data.data.content! }} />
				</div>
			</section>
			{props.data.data.relatedPosts !== null && (<section className="py-5">
				<div className="container">
					<div className="row">
						{props.data.data.relatedPosts.nodes?.slice(0, 3).map(post => (
							<div className="col-4">
								<PostCard post={post!} />
							</div>
						))}
					</div>
				</div>
			</section>)}
			<Footer navGroup={props.data.data.page_meta?.footer?.nav_links2!} />
		</>
	)
}
export default FeaturePage;

export const Head = (props: PageProps<{ data: Queries.WpPost }>) => {
	return (
		<SEO title={props.data.data.title!} description={props.data.data.page_meta?.description!.replace(/(<([^>]+)>)/gi, "")!} pathname={props.data.data.page_meta?.path!} />
	)
}

export const query = graphql`
	query ($id: Int!) {
		data: wpPost (databaseId:{eq:$id}){
      databaseId
			title
			content
			excerpt

			relatedPosts {
				nodes {
        	desiredSlug
          slug
          title
          excerpt
          featuredImage {
						node {
							localFile {
              	childImageSharp {
              		gatsbyImageData(width: 1000)
              	}
              }
              altText
            }
          }
          categories {
						nodes {
							slug
            }
          }
          
        }
      }

			featuredImage {
				node{
					sourceUrl
					altText
					localFile {
						childImageSharp {
							gatsbyImageData(width: 1000)
							# fluid(maxWidth: 450) {
							# 	...GatsbyImageSharpFluid
							# }
						}
					}
				}
			}

      page_meta {
        path
				description
				footer {
					...LinkGroup
				}
				navLink {
					...LinkGroup
				}
      }
    }
	}
`;