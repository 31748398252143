import React from "react";
import { Link } from "./LinkGropRender";
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import classNames from "classnames";

interface HeroProps {
	hero: Queries.WpHomepage_Hero & { image: Queries.WpMediaItem };
	enablePadding?: boolean;
	haveLink?: boolean;
}

export const Hero = (props: HeroProps) => {
	const image = getImage(props.hero.image.localFile as any);
	const enablePadding = props.enablePadding ?? true;
	const enableLink = props.haveLink ?? true;
	return (
		<div className={classNames("container", { enablePadding: "border-bottom" })}>
			<div className={classNames("my-5 text-center", { enablePadding: "px-4 pt-5" })}>
				<div>
					<h1 className="display-4 fw-bold">{props.hero.title}</h1>
					<div className="col-lg-6 mx-auto">
						<p className="lead mb-4" dangerouslySetInnerHTML={{ __html: props.hero.byline! }}></p>
						{enableLink && (<div className="d-grid gap-2 d-sm-flex justify-content-sm-center mb-5">
							<Link
								link={props.hero.callToAction!}
								className="btn btn-outline-primary"
							/>
						</div>)}
					</div>
				</div>
				<div className="overflow-hidden" style={{ /*maxHeight: "30vh"*/ }}>
					<div className="container px-5">
						<GatsbyImage image={image!} alt={props.hero.image.altText!} loading="eager" />
					</div>
				</div>
			</div>
		</div>
	)
}